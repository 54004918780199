"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderCompleteEvent = instanceOfOrderCompleteEvent;
exports.OrderCompleteEventFromJSON = OrderCompleteEventFromJSON;
exports.OrderCompleteEventFromJSONTyped = OrderCompleteEventFromJSONTyped;
exports.OrderCompleteEventToJSON = OrderCompleteEventToJSON;
var BookingInfo_1 = require("./BookingInfo");
var IntendedBookingService_1 = require("./IntendedBookingService");
var OrderCompleteEventType_1 = require("./OrderCompleteEventType");
/**
 * Check if a given object implements the OrderCompleteEvent interface.
 */
function instanceOfOrderCompleteEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "readableOrderReference" in value;
    isInstance = isInstance && "thirdPartyBookingProvider" in value;
    isInstance = isInstance && "bookingInfo" in value;
    return isInstance;
}
function OrderCompleteEventFromJSON(json) {
    return OrderCompleteEventFromJSONTyped(json, false);
}
function OrderCompleteEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, OrderCompleteEventType_1.OrderCompleteEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
        'orderId': json['orderId'],
        'readableOrderReference': json['readableOrderReference'],
        'thirdPartyBookingProvider': (0, IntendedBookingService_1.IntendedBookingServiceFromJSON)(json['thirdPartyBookingProvider']),
        'bookingInfo': (0, BookingInfo_1.BookingInfoFromJSON)(json['bookingInfo']),
    };
}
function OrderCompleteEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, OrderCompleteEventType_1.OrderCompleteEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
        'orderId': value.orderId,
        'readableOrderReference': value.readableOrderReference,
        'thirdPartyBookingProvider': (0, IntendedBookingService_1.IntendedBookingServiceToJSON)(value.thirdPartyBookingProvider),
        'bookingInfo': (0, BookingInfo_1.BookingInfoToJSON)(value.bookingInfo),
    };
}
