"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventServiceEventType = void 0;
exports.EventServiceEventTypeFromJSON = EventServiceEventTypeFromJSON;
exports.EventServiceEventTypeFromJSONTyped = EventServiceEventTypeFromJSONTyped;
exports.EventServiceEventTypeToJSON = EventServiceEventTypeToJSON;
/**
 *
 * @export
 */
exports.EventServiceEventType = {
    OrderStarted: 'orderStarted',
    OrderComplete: 'orderComplete',
    OrderFailed: 'orderFailed',
    OrderStalled: 'orderStalled',
    TicketsReady: 'ticketsReady',
    TicketsFailed: 'ticketsFailed',
    TicketsSent: 'ticketsSent',
    TicketsSendFailed: 'ticketsSendFailed',
    PaymentHeld: 'paymentHeld',
    PaymentFailed: 'paymentFailed',
    PaymentCaptureFailed: 'paymentCaptureFailed',
    PaymentCancelFailed: 'paymentCancelFailed',
    PaymentUpdateReadableOrderReferenceFailed: 'paymentUpdateReadableOrderReferenceFailed',
    InvoiceSent: 'invoiceSent',
    InvoiceSendFailed: 'invoiceSendFailed'
};
function EventServiceEventTypeFromJSON(json) {
    return EventServiceEventTypeFromJSONTyped(json, false);
}
function EventServiceEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EventServiceEventTypeToJSON(value) {
    return value;
}
