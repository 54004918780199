"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfInvoiceSendFailedEvent = instanceOfInvoiceSendFailedEvent;
exports.InvoiceSendFailedEventFromJSON = InvoiceSendFailedEventFromJSON;
exports.InvoiceSendFailedEventFromJSONTyped = InvoiceSendFailedEventFromJSONTyped;
exports.InvoiceSendFailedEventToJSON = InvoiceSendFailedEventToJSON;
var InvoiceSendFailedEventType_1 = require("./InvoiceSendFailedEventType");
/**
 * Check if a given object implements the InvoiceSendFailedEvent interface.
 */
function instanceOfInvoiceSendFailedEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    return isInstance;
}
function InvoiceSendFailedEventFromJSON(json) {
    return InvoiceSendFailedEventFromJSONTyped(json, false);
}
function InvoiceSendFailedEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, InvoiceSendFailedEventType_1.InvoiceSendFailedEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
    };
}
function InvoiceSendFailedEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, InvoiceSendFailedEventType_1.InvoiceSendFailedEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
    };
}
