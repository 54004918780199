"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceSentEventType = void 0;
exports.InvoiceSentEventTypeFromJSON = InvoiceSentEventTypeFromJSON;
exports.InvoiceSentEventTypeFromJSONTyped = InvoiceSentEventTypeFromJSONTyped;
exports.InvoiceSentEventTypeToJSON = InvoiceSentEventTypeToJSON;
/**
 *
 * @export
 */
exports.InvoiceSentEventType = {
    InvoiceSent: 'invoiceSent'
};
function InvoiceSentEventTypeFromJSON(json) {
    return InvoiceSentEventTypeFromJSONTyped(json, false);
}
function InvoiceSentEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function InvoiceSentEventTypeToJSON(value) {
    return value;
}
