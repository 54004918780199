"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventServiceEventFromJSON = EventServiceEventFromJSON;
exports.EventServiceEventFromJSONTyped = EventServiceEventFromJSONTyped;
exports.EventServiceEventToJSON = EventServiceEventToJSON;
var InvoiceSendFailedEvent_1 = require("./InvoiceSendFailedEvent");
var InvoiceSentEvent_1 = require("./InvoiceSentEvent");
var OrderCompleteEvent_1 = require("./OrderCompleteEvent");
var OrderFailedEvent_1 = require("./OrderFailedEvent");
var OrderStalledEvent_1 = require("./OrderStalledEvent");
var OrderStartedEvent_1 = require("./OrderStartedEvent");
var PaymentCancelFailedEvent_1 = require("./PaymentCancelFailedEvent");
var PaymentFailedEvent_1 = require("./PaymentFailedEvent");
var PaymentHeldEvent_1 = require("./PaymentHeldEvent");
var PaymentUpdateReadableOrderReferenceFailedEvent_1 = require("./PaymentUpdateReadableOrderReferenceFailedEvent");
var TicketsFailedEvent_1 = require("./TicketsFailedEvent");
var TicketsReadyEvent_1 = require("./TicketsReadyEvent");
var TicketsSendFailedEvent_1 = require("./TicketsSendFailedEvent");
function EventServiceEventFromJSON(json) {
    return EventServiceEventFromJSONTyped(json, false);
}
function EventServiceEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'invoiceSendFailed':
            return __assign(__assign({}, (0, InvoiceSendFailedEvent_1.InvoiceSendFailedEventFromJSONTyped)(json, true)), { type: 'invoiceSendFailed' });
        case 'invoiceSent':
            return __assign(__assign({}, (0, InvoiceSentEvent_1.InvoiceSentEventFromJSONTyped)(json, true)), { type: 'invoiceSent' });
        case 'orderComplete':
            return __assign(__assign({}, (0, OrderCompleteEvent_1.OrderCompleteEventFromJSONTyped)(json, true)), { type: 'orderComplete' });
        case 'orderFailed':
            return __assign(__assign({}, (0, OrderFailedEvent_1.OrderFailedEventFromJSONTyped)(json, true)), { type: 'orderFailed' });
        case 'orderStalled':
            return __assign(__assign({}, (0, OrderStalledEvent_1.OrderStalledEventFromJSONTyped)(json, true)), { type: 'orderStalled' });
        case 'orderStarted':
            return __assign(__assign({}, (0, OrderStartedEvent_1.OrderStartedEventFromJSONTyped)(json, true)), { type: 'orderStarted' });
        case 'paymentCancelFailed':
            return __assign(__assign({}, (0, PaymentCancelFailedEvent_1.PaymentCancelFailedEventFromJSONTyped)(json, true)), { type: 'paymentCancelFailed' });
        case 'paymentFailed':
            return __assign(__assign({}, (0, PaymentFailedEvent_1.PaymentFailedEventFromJSONTyped)(json, true)), { type: 'paymentFailed' });
        case 'paymentHeld':
            return __assign(__assign({}, (0, PaymentHeldEvent_1.PaymentHeldEventFromJSONTyped)(json, true)), { type: 'paymentHeld' });
        case 'paymentUpdateReadableOrderReferenceFailed':
            return __assign(__assign({}, (0, PaymentUpdateReadableOrderReferenceFailedEvent_1.PaymentUpdateReadableOrderReferenceFailedEventFromJSONTyped)(json, true)), { type: 'paymentUpdateReadableOrderReferenceFailed' });
        case 'ticketsFailed':
            return __assign(__assign({}, (0, TicketsFailedEvent_1.TicketsFailedEventFromJSONTyped)(json, true)), { type: 'ticketsFailed' });
        case 'ticketsReady':
            return __assign(__assign({}, (0, TicketsReadyEvent_1.TicketsReadyEventFromJSONTyped)(json, true)), { type: 'ticketsReady' });
        case 'ticketsSendFailed':
            return __assign(__assign({}, (0, TicketsSendFailedEvent_1.TicketsSendFailedEventFromJSONTyped)(json, true)), { type: 'ticketsSendFailed' });
        default:
            throw new Error("No variant of EventServiceEvent exists with 'type=".concat(json['type'], "'"));
    }
}
function EventServiceEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'invoiceSendFailed':
            return (0, InvoiceSendFailedEvent_1.InvoiceSendFailedEventToJSON)(value);
        case 'invoiceSent':
            return (0, InvoiceSentEvent_1.InvoiceSentEventToJSON)(value);
        case 'orderComplete':
            return (0, OrderCompleteEvent_1.OrderCompleteEventToJSON)(value);
        case 'orderFailed':
            return (0, OrderFailedEvent_1.OrderFailedEventToJSON)(value);
        case 'orderStalled':
            return (0, OrderStalledEvent_1.OrderStalledEventToJSON)(value);
        case 'orderStarted':
            return (0, OrderStartedEvent_1.OrderStartedEventToJSON)(value);
        case 'paymentCancelFailed':
            return (0, PaymentCancelFailedEvent_1.PaymentCancelFailedEventToJSON)(value);
        case 'paymentFailed':
            return (0, PaymentFailedEvent_1.PaymentFailedEventToJSON)(value);
        case 'paymentHeld':
            return (0, PaymentHeldEvent_1.PaymentHeldEventToJSON)(value);
        case 'paymentUpdateReadableOrderReferenceFailed':
            return (0, PaymentUpdateReadableOrderReferenceFailedEvent_1.PaymentUpdateReadableOrderReferenceFailedEventToJSON)(value);
        case 'ticketsFailed':
            return (0, TicketsFailedEvent_1.TicketsFailedEventToJSON)(value);
        case 'ticketsReady':
            return (0, TicketsReadyEvent_1.TicketsReadyEventToJSON)(value);
        case 'ticketsSendFailed':
            return (0, TicketsSendFailedEvent_1.TicketsSendFailedEventToJSON)(value);
        default:
            throw new Error("No variant of EventServiceEvent exists with 'type=".concat(value['type'], "'"));
    }
}
